<template>
  <div
    v-if="visible && expanded"
    class="fixed inset-0 z-50 flex flex-col items-center justify-center bg-theme-primary/50 p-8 backdrop-blur"
    @click="expanded = false"
  >
    <div class="relative min-h-0">
      <a
        href="https://instagram.com/voice.dubai"
        target="_blank"
        class="h-full w-full rounded-lg"
      >
        <img
          :src="imageUrl"
          class="h-full w-full rounded-lg"
          alt="Eva Dinner Show"
        />
      </a>
      <div class="absolute -top-4 dir-ltr:-right-4 dir-rtl:-left-4">
        <ADeepCircleButton @click="expanded = false">
          <template #icon><XBold /></template>
        </ADeepCircleButton>
      </div>
    </div>
  </div>
  <div
    v-else-if="visible && !expanded"
    class="fixed bottom-5 z-50 dir-ltr:left-5 dir-rtl:right-5"
  >
    <div
      class="h-16 w-16 cursor-pointer overflow-hidden rounded-full"
      @click="expanded = true"
    >
      <img
        :src="imageUrl"
        class="h-full w-full object-cover object-top"
        alt="Eva Dinner Show"
      />
    </div>
  </div>
</template>

<script>
import ADeepCircleButton from '@menu/Shared/ADeepCircleButton.vue';
import XBold from '@/phosphoricons/XBold.vue';

let expanded = true;

export default {
  components: {
    XBold,
    ADeepCircleButton,
  },
  data() {
    return {
      imageUrl: '/images/voice-karaoke-by-eva.jpeg',
      expanded: expanded,
    };
  },
  computed: {
    visible() {
      return false;
      // return true;
      // return this.$store.state.restaurant.slug === "evabeachhouse";
    },
  },
  watch: {
    expanded() {
      expanded = this.expanded;
    },
  },
};
</script>
