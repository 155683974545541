<template>
  <div
    v-if="running"
    class="fixed inset-0 z-50 flex flex-col items-center justify-center bg-theme-background p-10"
  >
    <div class="flex w-full max-w-sm flex-col items-center">
      <div>Downloading menu for offline access...</div>
      <div class="mt-2 h-3 w-full rounded-full bg-theme-primary/50">
        <div
          class="h-full rounded-full bg-theme-primary transition-[width]"
          :style="{ width: `${percents}%` }"
        ></div>
      </div>
      <div class="mt-2">{{ percents }}%</div>
      <button
        type="button"
        class="menu-button-primary mt-16 !px-4 !py-2 !text-xs"
        @click="skip"
      >
        Skip
      </button>
    </div>
    <div class="opacity-0">
      <img
        v-for="image in queuedImages"
        :key="image.id"
        :src="image.url"
        alt=""
        class="absolute left-0 top-0 h-1 w-1"
        @load="image.done = true"
        @error="image.done = true"
      />
    </div>
  </div>
</template>

<script>
import isStandalone from '@menu/Utils/isStandalone.js';
import uniq from 'lodash/uniq';

export default {
  data() {
    return {
      images: [],
    };
  },
  computed: {
    running() {
      return this.images.some((image) => !image.done);
    },
    percents() {
      return Math.ceil(
        (this.images.filter((image) => image.done).length /
          this.images.length) *
          100,
      );
    },
    queuedImages() {
      return this.images.filter(({ done }) => !done).slice(0, 10);
      // return this.images.filter(({ done }) => !done);
    },
  },
  // watch: {
  //   queuedImages(queuedImages) {
  //     console.log(queuedImages.map(({ id }) => id));
  //   },
  // },
  mounted() {
    if (this.shouldStart()) {
      this.start();
    }
  },
  methods: {
    shouldStart() {
      // return false;
      // return true;

      return isStandalone();
    },
    start() {
      const images = [];

      const restaurant = this.$store.state.restaurant;

      images.push(restaurant.banner?.url);
      images.push(restaurant.logo?.url);
      images.push(restaurant.fallbackProductImage?.url);

      restaurant.menus.forEach((menu) => {
        images.push(menu.homePageBackground?.url);
        images.push(menu.banner?.url);
        images.push(menu.defaultProductImage?.url);

        menu.categories.forEach((category) => {
          images.push(category.image?.thumbnailUrl);

          category.products.forEach((product) => {
            images.push(product.image?.url);
            images.push(product.image?.thumbnailUrl);
          });
        });
      });

      this.images = uniq(images)
        .filter((url) => !!url)
        .map((url, index) => ({
          id: index + 1,
          url,
          done: false,
        }));
    },
    skip() {
      this.images.forEach((image) => {
        image.done = true;
      });
    },
  },
};
</script>
