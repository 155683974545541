<template>
  <div
    class="flex items-center py-2 text-base"
    :class="{
      'cursor-pointer': !readonly,
    }"
  >
    <span
      v-if="!readonly"
      class="flex h-4 w-4 shrink-0 items-center justify-center border-2 dir-ltr:mr-2 dir-rtl:ml-2"
      aria-hidden="true"
      :class="[
        selected
          ? 'border-transparent bg-theme-primary'
          : 'border-theme-primary/50 bg-theme-background',
        checkbox ? 'rounded-sm' : 'rounded-full',
      ]"
    >
      <span
        v-if="selected && !checkbox"
        class="h-1.5 w-1.5 rounded-full bg-theme-background"
      />
      <span v-if="selected && checkbox">
        <CheckFatFill class="h-3 w-3 text-theme-background" />
      </span>
    </span>
    <div class="flex-1 dir-ltr:pr-3 dir-rtl:pl-3">
      {{ name }}
    </div>
    <div class="flex items-center">
      <div class="whitespace-nowrap font-semibold">
        <template v-if="price > 0">
          {{ pricePlus ? '+' : '' }}{{ $formatPrice(price) }}
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import CheckFatFill from '@/phosphoricons/CheckFatFill.vue';

export default {
  components: { CheckFatFill },
  props: {
    name: {
      type: String,
      default: null,
    },
    price: {
      type: Number,
      default: null,
    },
    checkbox: {
      type: Boolean,
      default: false,
    },
    pricePlus: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
