<template>
  <section
    class="relative cursor-pointer rounded-md bg-theme-background text-theme-text shadow-[rgba(var(--color-theme-text),0.2)_5px_5px_20px_0px] transition"
    @click="$store.commit('setPreviewingProduct', product)"
  >
    <div
      v-if="$store.state.isOrderMode"
      class="pointer-events-none absolute bottom-0.5 left-0.5 right-0.5 flex justify-end"
    >
      <div
        class="z-10 flex h-10 w-10 items-center justify-center rounded-full border-[3px] border-theme-background bg-theme-primary text-theme-button-text"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="icon-basket"
            d="M13.7428 5.5791C14.3859 5.5791 14.8618 6.17744 14.7171 6.8041L13.4444 12.3156C13.3717 12.7983 13.1762 13.2311 12.892 13.5388C12.6078 13.8466 12.2528 14.0097 11.8889 13.9997H4.11111C3.7472 14.0097 3.39223 13.8466 3.108 13.5388C2.82377 13.2311 2.62828 12.7983 2.55556 12.3156L1.28287 6.80409C1.13817 6.17744 1.61409 5.5791 2.25723 5.5791H13.7428Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M3.625 5.57895L5.8125 2M12.375 5.57895L10.1875 2"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
          ></path>
          <path
            d="M6.25 8.94727L6.25 10.6315"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
          ></path>
          <path
            d="M9.75 8.94727L9.75 10.6315"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
          ></path>
        </svg>
      </div>
    </div>
    <div
      v-if="isFirst && isTapForDetailsVisible"
      class="absolute inset-0 z-10 flex flex-col items-center justify-center rounded-md bg-theme-background/80 p-3 text-theme-text"
    >
      <svg
        class="block h-10 w-10 animate-[popping_ease-in_1s_infinite]"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 256"
      >
        <rect width="256" height="256" fill="none" />
        <path
          d="M56,76a52,52,0,0,1,104,0"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="16"
        />
        <path
          d="M64,224,34.68,174a20,20,0,0,1,34.64-20L88,184V76a20,20,0,0,1,40,0v56a20,20,0,0,1,40,0v16a20,20,0,0,1,40,0v36c0,24-8,40-8,40"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="16"
        />
      </svg>
      <div class="mt-2 max-w-full break-words text-center font-semibold">
        {{ $t('Tap for details') }}
      </div>
    </div>

    <div
      class="flex h-full"
      :class="{
        'flex-col': displayType === 'grid' || displayType === 'grid-extended',
        'flex-row-reverse': displayType === 'list',
      }"
    >
      <div
        v-if="
          displayType === 'grid' ||
          displayType === 'grid-extended' ||
          product.image?.thumbnailUrl ||
          defaultProductImageUrl
        "
        class="flex-shrink-0"
        :class="{
          'w-full': displayType === 'grid' || displayType === 'grid-extended',
          'w-32 p-3': displayType === 'list',
        }"
      >
        <div
          class="relative"
          :class="displayType === 'grid-extended' ? 'pb-[70%]' : 'pb-[100%]'"
        >
          <AImage
            class="absolute inset-0 h-full w-full overflow-hidden object-cover object-center"
            :class="{
              'rounded-tl-lg rounded-tr-lg':
                displayType === 'grid' || displayType === 'grid-extended',
              'rounded-md': displayType === 'list',
            }"
            :src="
              product.image?.thumbnailUrl ||
              defaultProductImageUrl ||
              $store.state.restaurant.fallbackProductImage?.url
            "
            :alt="$getTranslation(product.name)"
          />
        </div>
      </div>

      <div
        class="relative flex min-w-0 flex-col p-3"
        :class="{ 'flex-1': displayType === 'list' }"
      >
        <h3
          class="shrink-0 break-words font-medium leading-tight"
          :class="{
            'text-xl': displayType === 'grid-extended',
          }"
        >
          {{ $getTranslation(product.name) }}
        </h3>
        <div
          v-if="product.ingredientWarnings.length > 0"
          class="mt-0.5 text-xs italic text-theme-text/60"
        >
          {{
            product.ingredientWarnings
              .map((w) => $getTranslation(w.title, { fallbackToEn: true }))
              .join(', ')
          }}
        </div>
        <div
          v-if="
            $getTranslation(product.plainDescription) ||
            !shouldShowVariantDetails
          "
          class="mt-2 flex-1 shrink-0 font-secondary"
          style="min-height: 40px"
        >
          <div
            class="line-clamp-2 whitespace-pre-line break-words text-sm text-theme-text/80"
          >
            {{ $getTranslation(product.plainDescription) }}
          </div>
        </div>
        <div class="mt-2 flex shrink-0 items-center justify-between">
          <div v-if="shouldShowVariantDetails">
            <div
              v-for="variant in product.variants"
              :key="variant.id"
              class="mt-3 text-sm first:mt-0"
            >
              <p class="text-theme-text/80">
                {{ $getTranslation(variant.name) }}
              </p>
              <p class="text-sm font-semibold text-theme-text/80">
                {{ $formatPrice(variant.price) }}
              </p>
            </div>
          </div>
          <div v-else class="text-sm font-semibold text-theme-text/80">
            <span v-if="minPrice > 0" class="whitespace-nowrap">
              {{ $formatPrice(minPrice) }}
            </span>
            <template
              v-if="maxPrice > 0 && minPrice !== maxPrice && shouldShowRange"
            >
              -
              <span class="whitespace-nowrap">{{
                $formatPrice(maxPrice)
              }}</span>
            </template>
          </div>
        </div>
        <ProductBadges :product="product" class="mt-2 shrink-0 empty:hidden" />
      </div>
    </div>
  </section>
</template>

<script>
import ProductBadges from '@menu/Shared/ProductBadges.vue';
import AImage from '@menu/Shared/AImage.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  components: {
    AImage,
    ProductBadges,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    displayType: {
      type: String,
      required: true,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['isTapForDetailsVisible']),
    ...mapGetters(['menu']),
    prices() {
      return this.product.variants
        .map((variant) => variant.price)
        .filter(Boolean);
    },
    minPrice() {
      return this.prices.length > 0 ? Math.min(...this.prices) : null;
    },
    maxPrice() {
      return this.prices.length > 0 ? Math.max(...this.prices) : null;
    },
    shouldShowRange() {
      if (this.$store.state.restaurant.slug === 'saya-brasserie-cafe') {
        return false;
      }

      return true;
    },
    // todo: better long term solution
    shouldShowVariantDetails() {
      return (
        ['evabeachhouse', 'bebekrestaurantlounge'].includes(
          this.$store.state.restaurant.slug,
        ) &&
        this.product.variants.some((variant) =>
          this.$getTranslation(variant.name),
        )
      );
    },
    defaultProductImageUrl() {
      return this.menu?.defaultProductImage
        ? this.menu.defaultProductImage.url
        : null;
    },
  },
  mounted() {
    if (this.isFirst && this.isTapForDetailsVisible) {
      document.addEventListener('click', this.hideTapForDetailsOnDocumentClick);
    }
  },
  unmounted() {
    document.removeEventListener(
      'click',
      this.hideTapForDetailsOnDocumentClick,
    );
  },
  methods: {
    hideTapForDetailsOnDocumentClick() {
      this.$store.commit('hideTapForDetails');

      document.removeEventListener(
        'click',
        this.hideTapForDetailsOnDocumentClick,
      );
    },
  },
};
</script>
