<template>
  <button
    type="button"
    class="flex appearance-none flex-col border-l border-r border-t border-theme-primary/50 px-4 py-3 first:rounded-t-lg last:rounded-b-lg last:border-b"
  >
    <span class="flex items-center">
      <span
        class="flex h-4 w-4 shrink-0 items-center justify-center rounded-full border"
        aria-hidden="true"
        :class="
          isSelected
            ? 'border-transparent bg-theme-primary'
            : 'border-theme-primary/50 bg-theme-background'
        "
      >
        <span
          v-if="isSelected"
          class="h-1.5 w-1.5 rounded-full bg-theme-background"
        />
      </span>
      <span class="flex flex-1 flex-col items-start dir-ltr:ml-2 dir-rtl:mr-2">
        <span class="font-medium"><slot /></span>
        <span class="text-left text-sm empty:hidden">
          <slot name="description" />
        </span>
      </span>
    </span>

    <span
      class="mt-1 empty:hidden dir-ltr:ml-6 dir-rtl:mr-6"
      style="max-width: 200px"
    >
      <slot name="description-image" />
    </span>
  </button>
</template>

<script>
export default {
  props: {
    isSelected: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
