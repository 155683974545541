<template>
  <div
    class="fixed top-0 z-[60] flex h-screen w-64 max-w-[calc(100vw-100px)] shrink-0 flex-col bg-theme-background transition-all"
    :class="[
      expanded
        ? 'shadow-xl dir-ltr:ml-0 dir-rtl:mr-0'
        : 'dir-ltr:-ml-64 dir-rtl:-mr-64',
    ]"
  >
    <ADeepCircleButton class="mb-4 ml-4 mr-4 mt-4" @click="hide">
      <template #icon><XBold /></template>
    </ADeepCircleButton>

    <div
      v-prevent-body-scroll
      class="flex flex-col overflow-y-auto px-4 pb-20 pt-px"
    >
      <CategorySidebarItemCard
        v-for="category in categories"
        :key="category.id"
        :category="category"
        :focused="category.id === focusedCategoryId"
        @click="
          () => {
            hide();
            $emit('category-click', category);
          }
        "
      />
    </div>
  </div>

  <div
    class="fixed inset-0 z-50 bg-theme-background/50 backdrop-blur transition-all"
    :class="expanded ? 'opacity-100' : 'pointer-events-none opacity-0'"
    @click="hide"
  ></div>
</template>

<script>
import CategorySidebarItemCard from '@menu/Shared/CategorySidebarItemCard.vue';
import ADeepCircleButton from '@menu/Shared/ADeepCircleButton.vue';
import XBold from '@/phosphoricons/XBold.vue';

export default {
  components: { XBold, ADeepCircleButton, CategorySidebarItemCard },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: true,
    },
    focusedCategoryId: {
      type: [Number, null],
      required: true,
    },
  },
  emits: ['hide', 'category-click'],
  data() {
    return {
      touchStartX: 0,
      touchStartY: 0,
    };
  },
  mounted() {
    window.addEventListener('touchstart', this.onTouchStart);
    window.addEventListener('touchend', this.onTouchEnd);
  },
  unmounted() {
    window.removeEventListener('touchstart', this.onTouchStart);
    window.removeEventListener('touchend', this.onTouchEnd);
  },
  methods: {
    hide() {
      this.$emit('hide');
    },
    onTouchStart(event) {
      this.touchStartX = event.changedTouches[0].screenX;
      this.touchStartY = event.changedTouches[0].screenY;
    },
    onTouchEnd(event) {
      const distanceX = event.changedTouches[0].screenX - this.touchStartX;
      const distanceY = event.changedTouches[0].screenY - this.touchStartY;

      if (distanceX < -75 && Math.abs(distanceY) < 75) {
        this.hide();
      }
    },
  },
};
</script>
