<template>
  <Teleport to="body">
    <ProductPopup @hide="$store.commit('setPreviewingProduct', null)" />
    <ContentDownloader />
  </Teleport>

  <RouterView />
</template>

<script>
import { mapState } from 'vuex';
import ProductPopup from '@menu/Shared/ProductPopup.vue';
import ContentDownloader from '@menu/Shared/ContentDownloader.vue';

export default {
  components: { ContentDownloader, ProductPopup },
  computed: {
    ...mapState(['restaurant']),
  },
  created() {
    // todo: ????
    this.$router.afterEach(() => {
      window.scrollTo({ top: 0 });
    });
  },
};
</script>
