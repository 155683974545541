import '@/../css/menu/index.css';
import { createApp, h } from 'vue';
import { createStore } from 'vuex';
import { createRouter, createWebHistory } from 'vue-router';
import MenuPage from '@menu/Pages/MenuPage.vue';
import AboutPage from '@menu/Pages/AboutPage.vue';
import App from '@menu/App.vue';
import { createI18n } from 'vue-i18n';
import axios from 'axios';
import { Workbox } from 'workbox-window';
import { v4 as uuid } from 'uuid';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

import 'scroll-behavior-polyfill';
import HomePage from '@menu/Pages/HomePage.vue';

import { plugin as VueTippy } from 'vue-tippy';
import 'tippy.js/dist/tippy.css';

import BranchSelectionPage from '@menu/Pages/BranchSelectionPage.vue';
import FormPage from '@menu/Pages/FormPage.vue';
import isStandalone from '@menu/Utils/isStandalone.js';
import FavoritesPage from '@menu/Pages/FavoritesPage.vue';
import ClickOutside from '@/directives/ClickOutside.js';
import PlainMenuPage from '@menu/Pages/PlainMenuPage.vue';
import PreventBodyScroll from '@menu/Directives/PreventBodyScroll.js';
import CartPage from '@menu/Pages/CartPage.vue';
import OrderPage from '@menu/Pages/OrderPage.vue';
import OrderPayPage from '@menu/Pages/OrderPayPage.vue';
import * as Luxon from 'luxon';
import { isEqual } from 'lodash';

import Toast, { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import setUserOrigin from '@/utils/setUserOrigin';

function syncHeight() {
  const documentElementClientHeight = document.documentElement.clientHeight;
  const windowOuterHeight = window.outerHeight;
  const windowInnerHeight = window.innerHeight;

  let height = documentElementClientHeight;

  // [iOS] standalone
  if (navigator.standalone) {
    height = windowOuterHeight;
  }

  document.documentElement.style.setProperty(`--screen-height`, `${height}px`);

  document.documentElement.style.setProperty(
    `--document-element-client-height`,
    `${documentElementClientHeight}px`,
  );
  document.documentElement.style.setProperty(
    `--window-outer-height`,
    `${windowOuterHeight}px`,
  );
  document.documentElement.style.setProperty(
    `--window-inner-height`,
    `${windowInnerHeight}px`,
  );
}
syncHeight();
window.addEventListener('resize', syncHeight);
window.addEventListener('orientationchange', syncHeight);
setInterval(syncHeight, 1000);

const isOrderMode = window['sharedData']['isOrderMode'];
const isEmbedMode = window['sharedData']['isEmbedMode'];

try {
  if (new URL(location.href).searchParams.get('table')) {
    sessionStorage.setItem(
      'table',
      new URL(location.href).searchParams.get('table'),
    );
  }
} catch (e) {
  //
}

// const shouldRegisterSw =
//   new URL(location.href).searchParams.get("register_sw") === "1";

// register SW only in standalone mode
const shouldRegisterSw = isStandalone() && !isOrderMode && !isEmbedMode;

if ('serviceWorker' in navigator && shouldRegisterSw) {
  const wb = new Workbox('/sw-menu.js');

  // todo: double check with Google docs if it's a good appraoch
  wb.addEventListener('installed', (event) => {
    // reload page when sw is first installed to make sure
    // that all runtime caching works even during the first visit
    if (!event.isUpdate) {
      window.location.reload();
    }
  });

  wb.register();
}

Bugsnag.start({
  apiKey: window['sharedData']['global']['keys']['bugsnag'],
  plugins: [new BugsnagPluginVue()],
});

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: ['en'],
  formatFallbackMessages: true,
  // disable `[intlify] Not found 'Menu search' key in 'en' locale messages.` warnings
  silentTranslationWarn: true,
  messages: window['sharedData']['localizedMessages'],
});

let visitorUuid;

try {
  visitorUuid = localStorage.getItem('visitorUuid');
} catch (e) {
  // because sometimes `SecurityError: The operation is insecure.` happens
}

if (typeof visitorUuid !== 'string' || visitorUuid.length !== 36) {
  visitorUuid = uuid();
  try {
    localStorage.setItem('visitorUuid', visitorUuid);
  } catch (e) {
    // because sometimes `SecurityError: The operation is insecure.` happens
  }
}

const store = createStore({
  strict: true,
  state() {
    return {
      restaurant: window['sharedData']['restaurant'],
      visitorUuid,
      isOrderMode,
      isEmbedMode,
      onLine: navigator.onLine,
      favoriteProductIds: (() => {
        try {
          // it'll throw an exception if the structure is invalid
          return [
            ...JSON.parse(localStorage.getItem('favoriteProductIds')),
          ].filter(
            (favoriteProductId) => typeof favoriteProductId === 'number',
          );
        } catch (e) {
          // because sometimes `SecurityError: The operation is insecure.` happens
          // ...or because data is invalid for whatever reason
          return [];
        }
      })(),
      previewingProduct: null,
      isTapForDetailsVisible: (() => {
        if (!window['sharedData']['restaurant']['isTapForDetailsEnabled']) {
          return false;
        }

        try {
          return (
            localStorage.getItem('tapForDetailsLastUsedDate') !==
            new Date().toISOString().slice(0, 10)
          );
        } catch (e) {
          return false;
        }
      })(),
      cartItemsIds: (() => {
        try {
          // it'll throw an exception if the structure is invalid
          return [...JSON.parse(localStorage.getItem('cartItemsIds'))];
        } catch (e) {
          // because sometimes `SecurityError: The operation is insecure.` happens
          // ...or because data is invalid for whatever reason
          return [];
        }
      })(),
    };
  },
  getters: {
    menu(state) {
      // sometimes undefined
      const menuSlug = router.currentRoute.value.params.menuSlug;

      // sometimes null
      return (
        state.restaurant.menus.find(({ slug }) => slug === menuSlug) ?? null
      );
    },
    favoriteProducts(state, getters) {
      if (!getters.menu) return [];

      const favoriteProducts = [];

      getters.menu.categories.forEach((category) => {
        category.products.forEach((product) => {
          if (state.favoriteProductIds.includes(product.id)) {
            favoriteProducts.push(product);
          }
        });
      });

      return favoriteProducts;
    },
    availableLanguages(state) {
      return state.restaurant.languages;
    },
    availableCategoryTypes(state, getters) {
      return (
        state.restaurant.slug === 'saya-brasserie-cafe'
          ? [
              {
                name: i18n.global.t('Food'),
                slug: 'food',
              },
              {
                name: i18n.global.t('Desserts'),
                slug: 'desserts',
              },
              {
                name: i18n.global.t('Drinks'),
                slug: 'drinks',
              },
              {
                name: i18n.global.t('Shisha'),
                slug: 'shisha',
              },
              {
                name: i18n.global.t('Offers'),
                slug: 'offers',
              },
            ]
          : [
              {
                name: i18n.global.t('Food'),
                slug: 'food',
              },
              {
                name: i18n.global.t('Drinks'),
                slug: 'drinks',
              },
              {
                name: i18n.global.t('Desserts'),
                slug: 'desserts',
              },
              {
                name: i18n.global.t('Shisha'),
                slug: 'shisha',
              },
              {
                name: i18n.global.t('Offers'),
                slug: 'offers',
              },
            ]
      ).filter((categoryType) => {
        if (!getters.menu) {
          return false;
        }

        return getters.menu.categories.some((category) => {
          return category.type === categoryType.slug;
        });
      });
    },
    cartItems(state, getters) {
      if (!getters.menu || !getters.menu.modifierSets) return [];

      const cartItems = [];

      state.cartItemsIds.forEach((cartItemIds) => {
        getters.menu.categories.forEach((category) => {
          category.products.forEach((product) => {
            product.variants.forEach((variant) => {
              if (
                cartItemIds.productId === product.id &&
                cartItemIds.variantId === variant.id
              ) {
                const modifierIds = cartItemIds.modifierIds ?? [];

                const modifiers = [];

                getters.menu.modifierSets.forEach((modifierSet) => {
                  modifierSet.modifiers.forEach((modifier) => {
                    if (modifierIds.includes(modifier.id)) {
                      modifiers.push(modifier);
                    }
                  });
                });

                let price = 0;

                if (variant.price !== null) {
                  price += variant.price;
                }

                modifiers.forEach((modifier) => {
                  if (modifier.price !== null) {
                    price += modifier.price;
                  }
                });

                cartItems.push({
                  product,
                  variant,
                  modifiers,
                  price,
                  quantity: cartItemIds.quantity,
                });
              }
            });
          });
        });
      });

      return cartItems;
    },
    cartItemsTotalPrice(state, getters) {
      let totalPrice = 0;

      getters.cartItems.forEach((cartItem) => {
        totalPrice += cartItem.price * cartItem.quantity;
      });

      return totalPrice;
    },
    cartItemsTotalQuantity(state, getters) {
      let totalQuantity = 0;

      getters.cartItems.forEach((cartItem) => {
        totalQuantity += cartItem.quantity;
      });

      return totalQuantity;
    },
  },
  mutations: {
    setOnLine(state, onLine) {
      state.onLine = onLine;
    },
    setPreviewingProduct(state, product) {
      state.previewingProduct = product;
    },
    hideTapForDetails(state) {
      state.isTapForDetailsVisible = false;
      try {
        localStorage.setItem(
          'tapForDetailsLastUsedDate',
          new Date().toISOString().slice(0, 10),
        );
      } catch (e) {
        //
      }
    },
    addToFavorites(state, { productId }) {
      if (!state.favoriteProductIds.includes(productId)) {
        state.favoriteProductIds = [...state.favoriteProductIds, productId];
        try {
          localStorage.setItem(
            'favoriteProductIds',
            JSON.stringify(state.favoriteProductIds),
          );
        } catch (e) {
          // because sometimes `SecurityError: The operation is insecure.` happens
        }
      }
    },
    removeFromFavorites(state, { productId }) {
      state.favoriteProductIds = state.favoriteProductIds.filter(
        (favoriteProductId) => favoriteProductId !== productId,
      );
      try {
        localStorage.setItem(
          'favoriteProductIds',
          JSON.stringify(state.favoriteProductIds),
        );
      } catch (e) {
        // because sometimes `SecurityError: The operation is insecure.` happens
      }
    },
    setCartItemQuantity(
      state,
      { productId, variantId, modifierIds, quantity },
    ) {
      // We use `getters.cartItems` because it contains the latest data
      // where modifiers that don't exist anymore are excluded. It is
      // useful for matching cart items by `modifierIds`.
      const cartItemsIds = store.getters.cartItems.map((cartItem) => {
        return {
          productId: cartItem.product.id,
          variantId: cartItem.variant.id,
          modifierIds: cartItem.modifiers.map((modifier) => modifier.id),
          quantity: cartItem.quantity,
        };
      });

      const existingCartItem = cartItemsIds.find(
        (cartItem) =>
          cartItem.productId === productId &&
          cartItem.variantId === variantId &&
          isEqual(cartItem.modifierIds, modifierIds),
      );

      if (existingCartItem) {
        existingCartItem.quantity = quantity;
      } else {
        cartItemsIds.push({
          productId,
          variantId,
          modifierIds,
          quantity,
        });
      }

      state.cartItemsIds = cartItemsIds.filter(
        ({ quantity }) => quantity !== 0,
      );

      try {
        localStorage.setItem(
          'cartItemsIds',
          JSON.stringify(state.cartItemsIds),
        );
      } catch (e) {
        // because sometimes `SecurityError: The operation is insecure.` happens
      }
    },
    clearCartItems(state) {
      state.cartItemsIds = [];
      try {
        localStorage.removeItem('cartItemsIds');
      } catch (e) {
        // because sometimes `SecurityError: The operation is insecure.` happens
      }
    },
  },
});

function getPreferredLanguage() {
  let languageFromLocalStorage;

  try {
    languageFromLocalStorage = localStorage.getItem(
      `menuLanguage-${window['sharedData']['restaurant']['id']}`,
    );
  } catch (e) {
    // because sometimes `SecurityError: The operation is insecure.` happens
  }

  // todo: refactor
  try {
    if (!languageFromLocalStorage) {
      languageFromLocalStorage = window.navigator.language.slice(0, 2);
    }
  } catch (e) {
    //
  }

  return (
    window['sharedData']['restaurant']['languages'].find(
      (lang) => lang === languageFromLocalStorage,
    ) ?? window['sharedData']['restaurant']['languages'][0]
  );
}

function setPreferredLanguage(languageCode) {
  i18n.global.locale = languageCode;

  // todo: refactor

  const html = document.getElementsByTagName('html')[0];

  html.setAttribute('dir', languageCode === 'ar' ? 'rtl' : 'ltr');
  html.setAttribute('lang', languageCode);

  try {
    localStorage.setItem(
      `menuLanguage-${window['sharedData']['restaurant']['id']}`,
      languageCode,
    );
  } catch (e) {
    // because sometimes `SecurityError: The operation is insecure.` happens
  }
}

window.addEventListener('online', () => {
  store.commit('setOnLine', true);
});

window.addEventListener('offline', () => {
  store.commit('setOnLine', false);
});

// Listening to post messages from Apetito Menu dashboard.
window.addEventListener('message', (event) => {
  if (event.data === 'MenuUpdated') {
    location.reload();
  }
});

axios
  .post(`/api/menu-visits`, {
    restaurantId: store.state.restaurant.id,
    meta: {
      document: {
        referrer: document.referrer,
      },
      location: {
        href: location.href,
      },
      navigator: {
        language: navigator.language,
        languages: navigator.languages,
        maxTouchPoints: navigator.maxTouchPoints,
        standalone: navigator.standalone ?? null,
      },
      window: {
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      },
      matchMedia: {
        '(display-mode: standalone)': window.matchMedia(
          '(display-mode: standalone)',
        ).matches,
      },
      uuid: visitorUuid,
    },
  })
  .finally(() => {});

const routes = [
  {
    path: '/',
    name: 'root',
    beforeEnter: [
      () => {
        return {
          name: 'menuSelection',
          params: { languageCode: getPreferredLanguage() },
        };
      },
    ],
  },
  {
    path: '/:languageCode',
    beforeEnter: [
      (to) => {
        if (
          !store.state.restaurant.languages.some(
            (languageCode) => languageCode === to.params.languageCode,
          )
        ) {
          // todo: maybe to same page but with different language code?
          return { name: 'root' };
        }
      },
    ],
    children: [
      {
        path: '',
        name: 'menuSelection',
        component: BranchSelectionPage,
        beforeEnter: [
          (to) => {
            if (store.state.restaurant.isWelcomePageEnabled) {
              return;
            }

            if (store.state.restaurant.menus.length === 0) {
              return;
            }

            return {
              name: 'home',
              params: {
                languageCode: to.params.languageCode,
                menuSlug: store.state.restaurant.menus[0].slug,
              },
            };
          },
        ],
      },
      {
        path: 'order/:orderUuid',
        name: 'order',
        component: OrderPage,
      },
      {
        path: 'order/:orderUuid/pay',
        name: 'orderPay',
        component: OrderPayPage,
      },
      {
        path: 'menu/:menuSlug',
        beforeEnter: [
          (to) => {
            if (
              !store.state.restaurant.menus.some(
                (menu) => menu.slug === to.params.menuSlug,
              )
            ) {
              return {
                name: 'menuSelection',
                params: {
                  languageCode: to.params.languageCode,
                },
              };
            }
          },
        ],
        children: [
          {
            path: 'home',
            name: 'home',
            component: HomePage,
            beforeEnter: [
              (to) => {
                const menu = store.state.restaurant.menus.find(
                  (menu) => menu.slug === to.params.menuSlug,
                );

                // todo: make sure there is at least 1 category
                if (!menu.isHomePageEnabled) {
                  return {
                    name: 'menu',
                    params: {
                      languageCode: to.params.languageCode,
                      menuSlug: to.params.menuSlug,
                    },
                  };
                }
              },
            ],
          },
          { path: '', name: 'menu', component: MenuPage },
          { path: 'plain', name: 'plainMenu', component: PlainMenuPage },
          // todo: remove: ?
          {
            path: 'wishlist',
            redirect: { name: 'favorites' },
          },
          {
            path: 'favorites',
            name: 'favorites',
            component: FavoritesPage,
          },
          {
            path: 'cart',
            name: 'cart',
            component: CartPage,
            beforeEnter: [
              (to) => {
                if (!store.state.isOrderMode) {
                  return {
                    name: 'menu',
                    params: {
                      languageCode: to.params.languageCode,
                      menuSlug: to.params.menuSlug,
                    },
                  };
                }
              },
            ],
          },
          { path: 'about', name: 'about', component: AboutPage },
          {
            path: 'form',
            name: 'form',
            component: FormPage,
            beforeEnter: [
              (to) => {
                if (store.state.restaurant.forms.length === 0) {
                  return {
                    name: 'menu',
                    params: {
                      languageCode: to.params.languageCode,
                      menuSlug: to.params.menuSlug,
                    },
                  };
                }
              },
            ],
          },
          { path: ':pathMatch(.*)*', redirect: { name: 'menu' } },
        ],
      },
    ],
  },
  { path: '/:pathMatch(.*)*', redirect: '/' },
];

const router = createRouter({
  history: createWebHistory(window['sharedData']['routerBase']),
  routes,
});

const app = createApp({
  render: () => h(App),
});
app.config.globalProperties.$getTranslation = (
  translations,
  { fallbackToEn = false } = {},
) => {
  if (translations === null || translations === undefined) {
    return null;
  }

  const defaultLanguageCode = store.state.restaurant.languages[0];

  const languageCode =
    app.config.globalProperties.$route.params.languageCode ||
    defaultLanguageCode;

  return (
    translations[languageCode] ||
    translations[defaultLanguageCode] ||
    (fallbackToEn ? translations['en'] : null) ||
    ''
  );
};

router.afterEach((to) => {
  if (to.params.languageCode) {
    setPreferredLanguage(to.params.languageCode);
  }

  // todo: have not only "Menu - "
  document.title =
    store.state.restaurant.menus.length > 1 && store.getters.menu
      ? `Menu - ${
          store.state.restaurant.name
        } (${app.config.globalProperties.$getTranslation(
          store.getters.menu.name,
        )})`
      : `Menu - ${store.state.restaurant.name}`;
});

app.config.globalProperties.$formatPrice = (price, { currencyCode } = {}) => {
  currencyCode = currencyCode || store.state.restaurant.currencyCode;

  let defaultFractionDigits =
    store.state.restaurant.currencyFractionDigits ?? 2;

  const fractionDigits = price % 1 === 0 ? 0 : defaultFractionDigits;

  if (currencyCode === 'EUR') {
    return `${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price)}€`;
  }

  if (currencyCode === 'JPY') {
    return `¥${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    }).format(price)}`;
  }

  return `${currencyCode} ${new Intl.NumberFormat('en-US', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(price)}`;
};
app.config.globalProperties.$formatDateTime = (date, format) => {
  return Luxon.DateTime.fromISO(date).toFormat(format);
};
app.config.globalProperties.$toast = useToast();
app.config.globalProperties.window = window;
app.use(Bugsnag.getPlugin('vue'));
app.use(VueTippy, { defaultProps: { placement: 'top' } });
app.use(Toast, {
  transition: 'Vue-Toastification__fade',
  timeout: 2000,
});
app.use(router);
app.use(store);
app.use(i18n);
app.directive('click-outside', ClickOutside);
app.directive('prevent-body-scroll', PreventBodyScroll);
app.mount('#app');

setUserOrigin();
